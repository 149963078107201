<template>
	<el-card class="card-box" :body-style="{ padding: '10px' }">
		<img class="image" v-if="formData.mainImgUrl" :src="formData.mainImgUrl+'?x-oss-process=image/resize,m_fixed,h_220,w_220'" alt="">
		<div>
			<div class="name" style="font-size: 16px; height: 34px; line-height: 34px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
				{{ formData.goodsName }}
			</div>
			<div style="display: flex; justify-content: space-between;">
				<div>售价：￥<span style="font-weight: bold">{{ formData.salePrice }}</span></div>
				<div>活跃值：{{ formData.growRate }}</div>
			</div>
			<div style="padding: 8px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
				所属品牌：{{ formData.brandName }}
			</div>
			<div class="bottom">
				<el-button type="text" @click="editGiftFunc()">编辑</el-button>
				<el-switch v-model="formData.isActive" active-color="#13ce66" inactive-color="#ff4949" @change="changeState()"></el-switch>
				<el-button type="text" @click="deleteItem()">移除</el-button>
			</div>
		</div>
	</el-card>
</template>

<script>
	export default {
		props: {
			formData: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				activeName: 'first',
				shelfStatus: true,
			};
		},
		methods: {
			//编辑商品
			editGiftFunc() {
				this.publicToDetails({
					id: this.formData.goodsId
				},'/goods/editGoodDetails', this);
			},
			//移除商品
			deleteItem() {
				this.$emit('deleteItem', this.formData);
			},
			// 切换状态
			changeState() {
				this.$emit('changeStatus', this.formData);
			},
		}
	};
</script>

<style lang="scss" scoped>
	.time {
		font-size: 13px;
		color: #999;
	}
	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.button {
		padding: 0;
		float: right;
	}
	.image {
		width: 218px;
		height: 218px;
		display: block;
	}
</style>