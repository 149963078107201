<template>
	<div class="main-content">
		<el-form inline size="small" label-width="100px">
			<el-form-item label="商品名称：">
				<el-input class="input-width" v-model="filter.goodsName" placeholder="请输入商品名称搜索"></el-input>
			</el-form-item>
			<el-form-item label="商品编码：">
				<el-input class="input-width" v-model="filter.goodsCode" placeholder="请输入商品编码搜索"></el-input>
			</el-form-item>
			<el-form-item label="商品分类：">
				<el-cascader
						v-model="filter.categoryId"
						:options="categoryList"
						:props="{ value: 'id', label: 'categoryName' ,children:'childCategoryList'}"
						clearable ></el-cascader>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="filter.list" v-loading="tableLoading" border>
			<el-table-column label="商品">
				<template slot-scope="scope">
					<div class="product-info" style="align-items:flex-start">
						<img v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'" alt=""/>
						<svg-icon v-else icon-class="noImgIcon"/>
						<div style="min-height: auto;">
							<div style="display:flex;justify-content:space-between;align-items:flex-start;">
								<div style="width:220px;">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;" >{{scope.row.goodsName}}</pre>
								</div>
							</div>
							<div style="color:#909399">{{scope.row.goodsCode}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="salePrice" label="售价" width="150"></el-table-column>
			<el-table-column prop="growRate" label="活跃值" width="150"></el-table-column>
			<el-table-column prop="totalStock" label="库存" width="150"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
<!--					<el-button v-if="selectedArr.indexOf(scope.row.id) > -1" type="text">已添加</el-button>-->
					<el-button type="primary" size="mini" @click="addGoodsFunc(scope)">添加</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
					   :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
					   @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
	</div>
</template>

<script>
	import { getPagedListForGift, categoryList, upgradeGiftAdd } from '@/api/goods';
	export default {
		props: {
			formData: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				tableLoading: false,
				filter: {
					goodsName: '',
					goodsCode: '',
					goodsStatus: 1, //0： 全部；1：出售中；2 仓库中；3：已售罄，4：警戒
					categoryId: '', //分类ID
					pageNo: 1,
					pageSize: 10,
					total: 0,
					list: [],
				},
				categoryList: [],  //分类
				selectedArr: [],
			}
		},
		mounted() {
			this.search();
			this.getCategory();
			this.selectedArr = this.formData.selected || [];
		},
		methods: {
			//初始化加载数据
			async getCategory () {
				try {
					let { data } = await categoryList();
					this.formatCategoryList(data);
					this.categoryList = data;
				} catch (e) {
					console.log(e)
				}
			},
			search() {
				this.filter.pageNo = 1;
				this.getList();
			},
			pageSizeChange(size) {
				this.filter.pageSize = size;
				this.getList();
			},
			currentChange(page) {
				this.filter.pageNo = page;
				this.getList();
			},
			// 获取列表
			async getList() {
				this.tableLoading = true;
				let params = {
					goodsName: this.filter.goodsName,
					goodsCode: this.filter.goodsCode,
					goodsStatus: this.filter.goodsStatus,
					categoryId: this.filter.categoryId[2],
					pageNo: this.filter.pageNo,
					pageSize: this.filter.pageSize,
				};
				let { data, alertMsg } = await getPagedListForGift(params);
				if (data) {
					this.filter.list = data.records || [];
					this.filter.total = data.total;
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误！'});
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			//选中商品
			async addGoodsFunc({row, $index}) {
				let { success, alertMsg } = await upgradeGiftAdd({
					goodsId: row.id,
					giftType: this.formData.giftType,
					upgradeType: this.formData.upgradeType,
				});
				if(success) {
					this.$message({type: 'success', message: '添加成功！'});
					this.filter.list.splice($index, 1);
					// this.selectedArr.push(row.id);
					this.$emit('getList');
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误！'});
				}
			},
			//格式化类目数据
			formatCategoryList (array) {
				for (let i = 0; i < array.length; i++) {
					if (array[i].categoryLevel == 3) {
						delete array[i].childCategoryList;
					} else {
						this.formatCategoryList(array[i].childCategoryList);
					}
				}
			},
		}
	}
</script>