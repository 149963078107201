<template>
	<div class="main-content">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="C礼包" name="first">
				<p>共计：{{ firstList.length }}款商品</p>
				<div v-if="activeName === 'first'" class="card-content">
					<el-card class="card-box">
						<div class="add-card-btn" @click="addGiftFunc">
							<i class="el-icon-plus" style="font-size: 48px; font-weight: bold;"></i>
							<p style="color: #999999;">添加商品</p>
						</div>
					</el-card>
					<Item v-for="item in firstList" :key="item.id" :formData="item" @deleteItem="deleteItem" @changeStatus="changeStatus"></Item>
				</div>
			</el-tab-pane>

			<el-tab-pane label="B礼包（套餐）" name="second">
				<p>共计：{{ secondList.length }}款商品</p>
				<el-row v-if="activeName === 'second'" class="card-content">
					<el-card class="card-box">
						<div class="add-card-btn" @click="addGiftFunc">
							<i class="el-icon-plus" style="font-size: 48px; font-weight: bold;"></i>
							<p style="color: #999999;">添加商品</p>
						</div>
					</el-card>
					<Item v-for="item in secondList" :key="item.id" :formData="item" @deleteItem="deleteItem" @changeStatus="changeStatus"></Item>
				</el-row>
			</el-tab-pane>

			<el-tab-pane label="B礼包（自选）" name="third">
				<p>共计：{{ thirdTotal }} 款上架商品， 礼包商品单件总活跃值 {{ thirdGrowRate }}</p>
				<el-row v-if="activeName === 'third'" class="card-content">
					<el-card class="card-box">
						<div class="add-card-btn" @click="addGiftFunc">
							<i class="el-icon-plus" style="font-size: 48px; font-weight: bold;"></i>
							<p style="color: #999999;">添加商品</p>
						</div>
					</el-card>
					<Item v-for="item in thirdList" :key="item.id" :formData="item" @deleteItem="deleteItem" @changeStatus="changeStatus"></Item>
				</el-row>
			</el-tab-pane>
		</el-tabs>

		<el-dialog title="添加商品" :visible.sync="addGiftFlag" width="70%" @closed="clearDataFunc">
			<GoodsList v-if="addGiftData" :formData="addGiftData" @getList="getList"></GoodsList>
		</el-dialog>
	</div>
</template>

<script>
	import { upgradeGiftList, upgradeGiftRemove, setActiveStatus } from '@/api/goods';
	import Item from './item';
	import GoodsList from './goodsList';
	export default {
		name: 'giftPack',
		components: {
			Item,
			GoodsList,
		},
		data() {
			return {
				activeName: 'first',
				firstList: [],
				secondList: [],
				thirdList: [],

				addGiftFlag: false,
				addGiftData: null,

				thirdTotal: 0,
				thirdGrowRate: 0,
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			handleClick(tab, event) {
				this.getList();
			},
			async getList() {
				this.tableLoading = true;
				let params = {
					giftType: this.activeName === 'third' ? 2 : 1,
					upgradeType: this.activeName === 'first' ? 4 : 3,
				};
				let { data, alertMsg } = await upgradeGiftList(params);
				if(data) {
					this[this.activeName + 'List'] = data || [];
					let total = 0, growRate = 0;
					data.filter(item => {
						if(item.isActive) {
							total ++;
							growRate += (item.growRate*100);
						}
					});
					this.thirdTotal = total;
					this.thirdGrowRate = Math.floor(growRate)/100;

				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误！'});
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			//编辑商品
			editGiftFunc(row) {
				this.publicToDetails({ id: row.id},'/goods/editGoodDetails', this);
			},
			//显示添加礼包弹出窗
			addGiftFunc() {
				let arr = [];
				this[this.activeName + 'List'].filter(item => {
					arr.push(item.id);
				});
				this.addGiftData = {
					selected: arr,
					giftType: this.activeName === 'third' ? 2 : 1,
					upgradeType: this.activeName === 'first' ? 4 : 3,
				};
				this.addGiftFlag = true;
			},
			closeLayer(flag) {
				this.addGiftFlag = false;
				if(flag) {
					this.getList();
				}
			},
			//清楚选中内容
			clearDataFunc() {
				this.addGiftData = null;
			},
			//切换展示状态（未完成）
			async changeStatus(row) {
				console.log(!!row.isActive);
				let { success, alertMsg } = await setActiveStatus({
					activeStatus: !!row.isActive,
					giftId: row.giftId,
					URLLINK: true,
				});
				if(success) {
					this.$message({type: 'success', message: '修改成功！'});
					this.getList();
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误！'});
				}
			},
			//移除礼包
			deleteItem(row) {
				this.$confirm('确定要将此商品移出么？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let {success, alertMsg} = await upgradeGiftRemove({id: row.giftId});
					if (success) {
						this.$message({type: 'success', message: '移除成功！'});
						this.getList();
					} else {
						this.$message({type: 'error', message: alertMsg || '未知错误！'});
					}
				}).catch(() => {
				});
			},
		}
	};
</script>
<style lang="scss">
	.card-box {
		position: relative;
		width: 240px;
		height: 356px;
		margin: 0 10px 20px;
		font-size: 14px;
		cursor: pointer;
	}
	.add-card-btn {
		position: absolute;
		margin: auto;
		height: 80px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
	}
	.card-content {
		display: flex;
		flex-wrap:wrap;
	}
</style>